import {
  GET_PRODUCT_CATEGORIES,
  SET_LVL1_CATEGORIES,
  SET_LVL2_CATEGORIES,
  SET_OFFERED_SERVICES_LOADING,
  SET_FLANCER_LVL1_CATEGORIES,
  SET_SELECTED_LVL1_CATEGORIES,
  SET_BUSINESS_TYPE,
  SET_SELECTED_COVER_IMAGE,
  GET_ALL_PRODUCTS,
  SET_WEB_ADD_PRODUCT_TYPE,
  RESET_OFFERED_SERVICE_DATA,
  SET_BULK_PRODUCT,
  UPDATE_FILE_PERCENTAGE
} from '../types';

const INITIAL_STATE = {
  offeredServiceloading: false,
  b2bprodCategories: [],
  b2bLvl1Categories: [],
  flancerLvl1Categories: [],
  b2bLvl2CategoriesTree: [],
  selectedLvl1Categories: [],
  businessType: '1',
  selectedCoverImage: null,
  allProducts: [],
  webAddProductType: 'single-upload',
  bulkProduct: null,
  uploadProgress: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES:
      return { ...state, b2bprodCategories: action.payload };
    case SET_LVL1_CATEGORIES:
      return { ...state, b2bLvl1Categories: action.payload };
    case SET_FLANCER_LVL1_CATEGORIES:
      return { ...state, flancerLvl1Categories: action.payload };
    case SET_LVL2_CATEGORIES:
      return { ...state, b2bLvl2CategoriesTree: action.payload };
    case SET_OFFERED_SERVICES_LOADING:
      return { ...state, offeredServiceloading: action.payload };
    case SET_SELECTED_LVL1_CATEGORIES:
      return { ...state, selectedLvl1Categories: action.payload };
    case SET_BUSINESS_TYPE:
      return { ...state, businessType: action.payload };
    case SET_SELECTED_COVER_IMAGE:
      return { ...state, selectedCoverImage: action.payload };
    case GET_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    case SET_WEB_ADD_PRODUCT_TYPE:
      return { ...state, webAddProductType: action.payload };
    case SET_BULK_PRODUCT:
      console.log('SET_BULK_PRODUCT', action.payload);
      return { ...state, bulkProduct: action.payload };
    case UPDATE_FILE_PERCENTAGE:
      return { ...state, uploadProgress: action.payload };
    case RESET_OFFERED_SERVICE_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
