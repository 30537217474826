import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Tooltip,
  // Modal,
  // Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { GAEvents, handleDownloadDocFormate } from '../../utils/helper-funtions';

import Divider from '@mui/material/Divider';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import { read, utils } from 'xlsx';

import { connect } from 'react-redux';
import {
  DeleteConfirmationPopup,
  AddProductOptions,
} from '../../components/ActionPopup';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import getProductList, {
  deleteSingleProduct,
} from './redux/actions/mycatalog-action';
import {
  setSingleData,
  setPageActionType,
  resetAddCatalogData,
} from '../AddCatalog/redux/actions/add-catalog-action';
import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
import { loading } from '../../store/actions/rootActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
// import { ModalStyle } from '../../utils/constants';
import { resetFLAddServiceData } from '../FreeLancer/FLAddService/redux/actions/add-service-action';
import { useMediaQuery } from 'react-responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import {
  resetUploadPercentage,
  setBulkProduct,
  uploadBulkProduct,
} from '../OfferedServices/redux/actions/offered-services-form-action';
import { CLICKED, LOAD, MYCATALOG, prodType, serviceType } from '../../utils/constants';
import productPlaceholder from '../../assets/product-placeholder.png'

const theme = createTheme();
const B2B_TITLE = 'My Catalog';
const FRL_TITLE = 'My Services';

const B2B_CARD_TITLE = 'Add Products/Services to your Catalog';
const FRL_CARD_TITLE = 'Add Services to your Offerings';

const B2_CARD_MSG =
  'Easily add products/services to your catalog and get discovered by \nbusinesses!';
const FRL_CARD_MSG =
  'Easily add services to your profile and get \ndiscovered by businesses!';

const B2B_PRODUCT = 'Add a Product/Service';
const FRL_SERVICE = 'Add a Service';
function validateKeys(requiredKeys, obj) {
  console.log('requiredKeys,obj', requiredKeys, obj);
  const objKeys = Object.keys(obj);
  const extraKeys = requiredKeys.filter((key) => !objKeys.includes(key));
  const missingKeys = objKeys.filter((key) => !requiredKeys.includes(key));
  console.log('missingKeys,extraKeys', missingKeys, extraKeys);
  return {
    missingKeys: missingKeys.length > 0 ? missingKeys : null,
    extraKeys: extraKeys.length > 0 ? extraKeys : null,
    isValid: missingKeys.length === 0,
  };
}
const MyCatalogForm = ({
  allProducts,
  userData,
  getProductList,
  setPageActionType,
  setSingleData,
  deleteSingleProduct,
  loading,
  isLoading,
  resetFLAddServiceData,
  resetAddCatalogData,
  pushMessage,
  businessType,
  setBulkProduct,
  uploadBulkProduct,
  bulkProduct,
  uploadProgress,
  resetUploadPercentage
}) => {
  // <<<<<<< HEAD
  //   let history = useHistory();
  let location = useLocation();

  // const [userType, setUserType] = useState('B2B');
  //   const [open, setOpen] = useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  //   const [doc, setDoc] = useState();

  // =======
  let history = useHistory();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [sku, setSku] = React.useState();
  const handleDelete = async () => {
    console.log('Item deleted');
    loading(true);
    await deleteSingleProduct(sku, userData.accessToken);
    await getProductList(userData.registration.id, userData.accessToken);
    pushMessage('Product Catalog Deleted SuccessFully');
    loading(false);
    setOpenPopup(false);
  };

  function truncateText(text = '', length = 50) {
    if (text?.length <= length) {
      return text || '';
    } else if (text?.length == undefined) {
      return text;
    }
    return text?.substr(0, length) + '\u2026' || '';
  }

  useEffect(()=>{
    GAEvents(MYCATALOG, 'My Catalog', LOAD, {
      page_title: "My Catalog",
      page_location: window.location.href,
      page_path: window.location.pathname,
      userId: userData?.id || sessionStorage.getItem('random_id'),
      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')
    });

  },[]);
  useEffect(() => {
    // if (location?.state?.userType) {
    //   console.log('location?.state?.userType', location?.state?.userType);
    //   setUserType(location?.state?.userType);
    // }
    loading(true);
    getProductList(userData?.registration?.id, userData?.accessToken);
    loading(false);
  }, [userData, location?.state?.userType]);

  useEffect(()=>{},[uploadProgress]);
  /* eslint-disable no-unused-vars */
  const editProduct = (data) => {
    if (typeof data?.subCategoryId === 'string') {
      data.category_id = Number(data?.category_id);
    }
    setPageActionType('Edit');
    setSingleData(data);
    if (userData?.registration?.registration_type === 2) {
      history.push('/add-catalog');
    } else {
      history.push('/fl-add-service');
    }
  };
  /* eslint-enable no-unused-vars */
  // <<<<<<< HEAD
  //   const deleteProduct = async (sku) => {
  //     loading(true);
  //     await deleteSingleProduct(sku, userData.accessToken);
  //     await getProductList(userData?.registration?.id, userData?.accessToken);
  //     loading(false);
  // =======
  const deleteProduct = async (_sku) => {
    setSku(_sku);
    setOpenPopup(true);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const [openAddProductOptions, setAddProductOptions] = React.useState();
  const handleAddProdoctOptions = (type) => {
    console.log('handleAddProdoctOptions==', type);
    if (type === 'bulk-upload') {
      console.log('bulk-upload');
    } else {
      history.push('/add-catalog');
    }
    resetUploadPercentage();
  };

  const handleCatalogUploadFileChange = (e) => {
    const selectedFile = e.target.files;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      const requiredKeyValueObj = businessType == 1 ? prodType : serviceType;
      const existingArrayAttribute = jsonData[0];
      // const wantToEdit = existingArrayAttribute?.includes('sku');
      const res = validateKeys(existingArrayAttribute, requiredKeyValueObj);

      if (res?.isValid) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('registrationId', userData.registration.id);
        await uploadBulkProduct(
          formData,
          (_res) => {
            setBulkProduct(file);
            console.log('bulkProduct in store', bulkProduct);
            pushMessage(_res);
            setAddProductOptions(false);
            resetUploadPercentage();
          },
          (err) => {
            setBulkProduct(null);
            setAddProductOptions(false);
            pushMessage(err);
            resetUploadPercentage();
          }
        );
      } else {
        setAddProductOptions(false);
        pushMessage('Invalid file format.');
      }
    };

    reader.readAsArrayBuffer(file);
    e.target.value = null;
    console.log('Catalog File:', selectedFile);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={ModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Elynker
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to delete the {userData?.registration?.registration_type === 2 ? 'product' : 'catalog'}?
            </Typography>
            <div  style={{ float: 'right' }} >
                <Button onClick={()=>{
                    handleClose();
                    deleteProduct(doc.sku);
                }}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
            </div>
            </Box>
        </Modal> */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.goBack()} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {userData?.registration?.registration_type === 2
                ? B2B_TITLE
                : FRL_TITLE}
            </Typography>
            <Card className="catalog-card mb-3">
              <CardContent className="catalog-content">
                <Typography
                  variant="h5"
                  className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                  component="div"
                >
                  {userData?.registration?.registration_type === 2
                    ? B2B_CARD_TITLE
                    : FRL_CARD_TITLE}
                </Typography>
                <Typography
                  variant="body2"
                  className="fs-14 text-white mb-3 font-gotham"
                >
                  {userData?.registration?.registration_type === 2
                    ? B2_CARD_MSG
                    : FRL_CARD_MSG}
                </Typography>
                <Button
                  variant="contained"
                  className="add-product-btn"
                  onClick={() => {

                    GAEvents(MYCATALOG, "Add", CLICKED, {
                      userId: userData?.id || sessionStorage.getItem('random_id'),
                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                      title: `Add + ${userData?.registration?.registration_type === 2
                        ? B2B_PRODUCT
                        : FRL_SERVICE}`
                    });
                    resetAddCatalogData();
                    resetFLAddServiceData();
                    if (userData?.registration?.registration_type === 2) {
                      if (!isTabletOrMobile) {
                        setAddProductOptions(true);
                      } else {
                        history.push('/add-catalog');
                      }
                    } else {
                      history.push('/fl-add-service');
                    }
                  }}
                >
                  {userData?.registration?.registration_type === 2
                    ? B2B_PRODUCT
                    : FRL_SERVICE}
                </Button>
                <AddProductOptions
                  open={openAddProductOptions}
                  onClose={() => {
                    setAddProductOptions(false);
                    resetUploadPercentage();
                  }}
                  onConfirm={handleAddProdoctOptions}
                  handleDownloadDocFormate={handleDownloadDocFormate}
                  handleCatalogUploadFileChange={handleCatalogUploadFileChange}
                  isEnabled={uploadProgress > 0 ? true : false}
                  updatePercent={uploadProgress}
                />
              </CardContent>
            </Card>
            <List>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={1.5}
                alignItems="stretch"
              >
                {allProducts &&
                  allProducts.map((item) => {
                    return (
                      <>
                        {isTabletOrMobile && (
                          <>
                            <Grid item md={6} lg={6} xs={12} key={item.id}>
                              <ListItem className="list-item">
                                <Card className="card d-flex">
                                  <CardMedia
                                    className="media"
                                    image={
                                      item.default_image
                                        ? `${PRODUCT_IMAGE_BASE_URL}${item.default_image}`
                                        : productPlaceholder
                                    }
                                  />
                                  <CardContent className="content">
                                    <div className="d-flex justify-content-between align-items-start">
                                      <Tooltip title={item.title} arrow>
                                        <Typography
                                          variant="h5"
                                          className="fs-16 text-black mb-2 fw-bold font-skmodernist"
                                          component="div"
                                        >
                                          {/* {item.title.length > 10
                                            ? item.title.slice(0, 10) + '..'
                                            : item.title} */}
                                            {truncateText(item?.title,25)}
                                        </Typography>
                                      </Tooltip>
                                      <div className="actions mt-0">
                                        <IconButton
                                          className="cat-edit-icon p-0"
                                          onClick={() => {
                                            GAEvents(MYCATALOG, "Edit", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: item?.title || ''
                                            });
                                            resetAddCatalogData();
                                            resetFLAddServiceData();
                                            editProduct(item);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          className="cat-delete-icon p-0"
                                          onClick={() =>{
                                            GAEvents(MYCATALOG, "Delete", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: item?.title || ''
                                            });
                                            deleteProduct(item.sku);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </div>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-grey mb-2 font-gotham"
                                    >
                                      {/* {item?.description == undefined ||
                                        item?.description == 'undefined'
                                        ? ''
                                        : item?.description.length > 10
                                          ? item?.description.slice(0, 10) + '..'
                                          : item?.description} */}
                                      {truncateText(item?.description)}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                    >
                                      ₹
                                      {isNaN(parseFloat(item.budget)) ? 0 : parseInt(item.budget)}{' '}
                                      <span className="fs-13 text-grey fw-light">
                                        /{' '}
                                        {userData?.registration
                                          ?.registration_type === 2
                                          ? item.unit || 'Piece'
                                          : 'Hour'}
                                      </span>
                                    </Typography>
                                    <div>
                                      <Typography className="fs-14 font-gotham text-darkgrey">
                                        Type:{' '}
                                        <span>
                                          {item?.type == '1'
                                            ? 'Product'
                                            : 'Service'}
                                        </span>
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </Card>
                              </ListItem>
                            </Grid>
                          </>
                        )}
                        {isDesktopOrLaptop && (
                          <>
                            <Grid item md={6} lg={6} xs={12} key={item.id}>
                              <ListItem className="list-item">
                                <Card className="card d-flex align-items-center">
                                  <CardMedia
                                    className="media"
                                    // src={`${PRODUCT_IMAGE_BASE_URL}item.default_image`}
                                    image={
                                      item.default_image
                                        ? `${PRODUCT_IMAGE_BASE_URL}${item.default_image}`
                                        : productPlaceholder
                                    }
                                  />
                                  <CardContent className="content ">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <Typography
                                          variant="h5"
                                          className="fs-22 text-black mb-2 fw-bold font-skmodernist"
                                          component="div"
                                        >
                                          {item.title}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          className="fs-14 text-grey fw-normal mb-2 font-gotham"
                                        >
                                          {/* {item?.description == undefined ||
                                            item?.description == 'undefined'
                                            ? ''
                                            : item?.description} */}
                                          {truncateText(item?.description)}
                                        </Typography>
                                      </div>

                                      <div>
                                        <Typography
                                          variant="h5"
                                          className="fs-14 text-darkgrey mb-0 font-skmodernist"
                                        >
                                          Starting at
                                        </Typography>

                                        <Typography
                                          variant="h5"
                                          className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                        >
                                          ₹
                                          {isNaN(item.budget) ? 0 : parseInt(item.budget)}{' '}
                                          <span className="fs-13 text-grey fw-light">
                                            /{' '}
                                            {userData?.registration
                                              ?.registration_type === 2
                                              ? item.unit || 'Piece'
                                              : 'Hour'}
                                          </span>
                                        </Typography>
                                      </div>

                                      <div className="actions mt-0">
                                        <IconButton
                                          className="cat-edit-icon p-0"
                                          onClick={() => {
                                            GAEvents(MYCATALOG, "Edit", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: item?.title || ''
                                            });
                                            resetAddCatalogData();
                                            resetFLAddServiceData();
                                            editProduct(item);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          className="cat-delete-icon p-0"
                                          // onClick={() => {
                                          //   setDoc(item);
                                          //   handleOpen();

                                          // }}
                                          onClick={() =>{
                                            GAEvents(MYCATALOG, "Delete", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: item?.title || ''
                                            });
                                            deleteProduct(item.sku);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </div>
                                    <div>
                                      <Typography className="fs-14 font-gotham text-darkgrey">
                                        Type:{' '}
                                        <span>
                                          {item?.type == '1'
                                            ? 'Product'
                                            : 'Service'}
                                        </span>
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </Card>
                              </ListItem>
                            </Grid>
                          </>
                        )}
                      </>
                    );
                  })}
              </Grid>
            </List>

            {/* <form style={{ marginTop: '40px' }}>
                            <div className=" mb-3">
                                <InputLabel
                                    htmlFor="inputCompanyName"
                                    className="text-start fs-14 text-black font-skmodernist">
                                    Professional Role
                                </InputLabel>
                                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                    <OutlinedInput
                                        id="inputCompanyName"
                                        // label="Mobile Number"
                                        type="text"
                                        placeholder="Enter your role"
                                        required
                                        autoFocus
                                        fullWidth
                                        margin='none'

                                    />
                                </FormControl>
                            </div>

                            <div style={{ marginTop: '100px' }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                                    About you
                                </Button>
                                <Button variant="text"
                                    type="button"
                                    fullWidth
                                    className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black">
                                    Back
                                </Button>
                            </div>

                        </form> */}

            {isDesktopOrLaptop && (
              <>
                <Box className=" my-4">
                  <Button
                    onClick={() => {
                      GAEvents(MYCATALOG, "Back", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: 'Back'
                      });
                      history.goBack();
                    }}
                    variant="text"
                    type="button"
                    className="btn-web-back"
                  >
                    Back
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <DeleteConfirmationPopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onConfirm={handleDelete}
          message={
            userData?.registration?.registration_type === 2
              ? 'Are you sure you want to delete product?'
              : 'Are you sure you want to delete service?'
          }
        />
      </Container>
    </ThemeProvider>
  );
};

MyCatalogForm.propTypes = {
  getProductList: PropTypes.func,
  allProducts: PropTypes.array,
  userData: PropTypes.object,
  setSingleData: PropTypes.func,
  setPageActionType: PropTypes.func,
  deleteSingleProduct: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  resetFLAddServiceData: PropTypes.func,
  resetAddCatalogData: PropTypes.func,
  pushMessage: PropTypes.func,
  setBulkProduct: PropTypes.func,
  uploadBulkProduct: PropTypes.func,
  businessType: PropTypes.any,
  bulkProduct: PropTypes.any,
  uploadProgress: PropTypes.any,
  resetUploadPercentage: PropTypes.func
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { allProducts } = state.mycatalog;
  const { isLoading } = state.root;
  const { businessType, bulkProduct, uploadProgress } = state.offeredServices;
  return {
    userData,
    allProducts,
    isLoading,
    businessType,
    bulkProduct,
    uploadProgress
  };
};

export default connect(mapStateToProps, {
  getProductList,
  setSingleData,
  setPageActionType,
  deleteSingleProduct,
  loading,
  resetFLAddServiceData,
  resetAddCatalogData,
  pushMessage,
  setBulkProduct,
  uploadBulkProduct,
  resetUploadPercentage
})(MyCatalogForm);
