import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { stickyButtonStyle } from '../../utils/sx-styles';
import {
  Container,
  Grid,
  Divider,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import SubscriptionBanner from '../../assets/subscription-banner.png';
// import { ReactComponent as CheckCircleOutline } from '../../assets/checkmark-circle-sharp.svg';
import { ReactComponent as CheckCircleGreenIcon } from '../../assets/check-outline-green-icon.svg';
// import { ReactComponent as WhatsappBusinessIcon } from '../../assets/package-whatsapp-business-icon.svg';
// import { ReactComponent as GoogleBusinessIcon } from '../../assets/package-google-business-icon.svg';
// import { ReactComponent as SinglePageIcon } from '../../assets/package-single-page-icon.svg';
// import { ReactComponent as InstaIcon } from '../../assets/package-instagram-icon.svg';
// import { ReactComponent as FacebookIcon } from '../../assets/package-facebook-icon.svg';
// import { ReactComponent as LinkedinIcon } from '../../assets/package-linkedin-icon.svg';
// import { ReactComponent as SubscriptionMan } from '../../assets/subscription-man-standing.svg';
import SubscriptionMan from '../../assets/subscription-man-standing.png';
import { ReactComponent as QuickOnboardingIcon } from '../../assets/quick-onboarding-icon.svg';
import { ReactComponent as SolutionsMadeIcon } from '../../assets/solutions-made-icon.svg';
import { ReactComponent as GrowBusinessIcon } from '../../assets/grow-business-icon.svg';
// import BasicSubscription from '../../assets/basic-subscription-img.png';
// import EssentialSubscription from '../../assets/essential-subscription-img.png';
// import EstarterSubscription from '../../assets/estarter-subscription-img.png';
// import LimitedOFferImg from '../../assets/limited-offer-img.png';
import { connect, useDispatch } from 'react-redux';
import DrawerForm from '../../components/DrawerForm';
import PropTypes from 'prop-types';

import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import useValidation from '../../hooks/useValidation';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import {
  getEnquiryById,
  postEnquiry,
} from '../B2BCategory/redux/actions/b2b-category-action';
import { useEffect } from 'react';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  resetTemplateData,
  setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import {
  B2C_ID,
  CALLBACK_TYPE_SUBSCRIPTION,
  DIALOG_ALREADY_SENT_SUBSCRIPTION_REQ_MSG,
  DIALOG_ALREADY_SUBSCRIPTION_REQ_MSG,
  DIALOG_NOT_LOGIN_MSG,
  DIALOG_SUBSCRIPTION_REQ_MSG,
  DIALOG_SUB_REQ_ERR_MSG,
  // DIALOG_SUB_REQ_SUCC_MSG,
  DIALOG_TITLE,
  SUBSCRIPTION_TERMS,
  T_AND_C_SUB_SLUG,
} from '../../utils/constants';
import { loading } from '../../store/actions/rootActions';

import { useMediaQuery } from 'react-responsive';
import {
  GAEvents,
  isMoreThan24HoursAgo,
  sendDefaultPageEvent,
} from '../../utils/helper-funtions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getUpdatedUserObjData } from '../Otp/redux/actions/submit-code-action';

const theme = createTheme();
const SubscriptionnForm = ({
  userData,
  postEnquiry,
  pushMessage,
  enquiry,
  isLoading,
  setTemplateRender,
  resetTemplateData,
  getEnquiryById,
  // isSubscribed,
  getUpdatedUserObjData
}) => {
  console.log('inside SubscriptionnForm');
  // console.log('enquiry1211 isSubscribed', userData?.offlineSubscription?.isSubscribed);
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = userData || {};
  const { mobile_number = '', name = '', email = '' } = user || {};
  const { validateInput, errors } = useValidation();
  const [openCallModal, setOpenCallModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const [openSubEnq, setOpenSubEnq] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);
  // const handleOpenSubModal = () => setOpenSubEnq(true);
  const handleCloseSubModal = () => setOpenSubEnq(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [openSuccCallModal, setOpenSuccCallModal] = useState(false);
  const handleOpenSuccCallModal = () => setOpenSuccCallModal(true);
  const handleCloseSuccCallModal = () => setOpenSuccCallModal(false);

  const [openErrCallModal, setOpenErrCallModal] = useState(false);
  const handleOpenErrCallModal = () => setOpenErrCallModal(true);
  const handleCloseErrCallModal = () => setOpenErrCallModal(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  let userType;

  useEffect(() => {
    dispatch({
      type: SET_ENQUIRY,
      payload: {},
    });
    window.scrollTo(0, 0);
    sendDefaultPageEvent(
      userData,
      'Subscription_Load',
      window.location.href,
      window.location.pathname
    );
  }, []);

  const toggleCallBackDrawer = () => {
    userType = userData?.registration?.registration_type || 0;
    if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: true, redirect_to: '/subscription' },
        });
        history.push('/login');
      } else {
        setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
      }
    } else if (enquiry && Object.keys(enquiry)?.length == 0) {
      if (!userData) {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: true, redirect_to: '/subscription' },
        });
        handleOpenCallModal();
        // history.push('/login');
      } else {
        if (userType !== 1) {
          setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
        } else {
          dispatch({
            type: SET_ENQUIRY,
            payload: { isTrue: true, redirect_to: '/subscription' },
          });
          handleOpenCallModal();
        }
      }
    } else if (userData) {
      if (userData !== 1) {
        if (!isMoreThan24HoursAgo(enquiry?.createdAt)) {
          pushMessage(
            'We already received your enquiry. Admin will get back to you soon'
          );
        } else {
          setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
        }
      } else {
        dispatch({
          type: SET_ENQUIRY,
          payload: { isTrue: true, redirect_to: '/subscription' },
        });
        handleOpenCallModal();
      }
    } else {
      pushMessage(
        'We already received your enquiry. Admin will get back to you soon'
      );
    }
  };

  // useEffect(() => {
  //   const regId = userData?.registration.id;
  //   getEnquiryById(regId, CALLBACK_TYPE_SUBSCRIPTION);
  //   // if(enquiry)
  //   if (enquiry?.isTrue == true) {
  //     setIsCallBackDrawerOpen(true);
  //   }
  // }, []);
  useEffect(() => {
    const regId = userData?.registration.id;

    const fetchData = async () => {
      if (userData?.user?.id){
        await getUpdatedUserObjData(userData?.user?.id, ()=>{
          loading(false);
        }, ()=>{
          loading(false);
        });
      } 
      await getEnquiryById(regId, CALLBACK_TYPE_SUBSCRIPTION, (enq, r) => {
        console.log('useEffect userData,enquiry', userData, enquiry);

        if (r == true) {
          //got obj from server
          if (enquiry?.isTrue == true) {
            if (enq) {
              //already sent enquiry
              dispatch({
                type: SET_ENQUIRY,
                payload: { ...enq, isTrue: true },
              });
              setIsCallBackDrawerOpen(false);
            } else {
              setIsCallBackDrawerOpen(true);
            }
          } else {
            if (enq) {
              //already sent enquiry
              dispatch({
                type: SET_ENQUIRY,
                payload: { ...enq, isTrue: true },
              });
              setIsCallBackDrawerOpen(false);
            }
          }
          // if (enquiry?.isTrue) {
          //   dispatch({
          //     type: SET_ENQUIRY,
          //     payload: { ...enq[0], isTrue: true },
          //   });
          //   toggleCallBackDrawer();
          // }
          loading(false);
        } else {
          //no enq data found on server
          if (enquiry?.isTrue) {
            setIsCallBackDrawerOpen(true);
          } else {
            setIsCallBackDrawerOpen(false);
          }
          loading(false);
        }
      });
    };
    loading(true);
    fetchData();
    // loading(false);
  }, []);

  useEffect(() => {
    setIsCallBackDrawerOpen(false);
  }, []);

  const requestCallBackSubmit = async (formData) => {
    // const{email,name,mobile_number}=userData
    loading(true);
    const registrationId = userData?.registration?.id;
    if (registrationId) {
      const { mobileNumber, comment, email, name, ...rest } = formData;
      let sEmail = email ? email : '';
      let sname = name ? name : '';
      const details = {
        ...rest,
        name: sname,
        email: sEmail,
        comments: comment,
        type: CALLBACK_TYPE_SUBSCRIPTION,
        phone_number: mobileNumber,
        registrationId,
      };

      // const postResponse = await postEnquiry(registrationId, details);
      const postResponse = await postEnquiry(registrationId, details);
      console.log('enquiry response ', postResponse);
      loading(false);
      if (postResponse?.message === 'Record Created') {
        handleCloseErrCallModal();
        handleOpenSuccCallModal();
      } else {
        handleCloseSuccCallModal();
        if (userData?.offlineSubscription?.isSubscribed) {
          handleOpenSuccCallModal();
        } else {
          handleOpenErrCallModal();
        }
      }
      // pushMessage(postResponse.message);
      dispatch({
        type: SET_ENQUIRY,
        payload: { isTrue: false, redirect_to: '/' },
      });
    }
    setIsCallBackDrawerOpen(false);
  };

  const handleSubTermsCondition = () => {
    setTemplateRender(SUBSCRIPTION_TERMS);
    history.push(`/${T_AND_C_SUB_SLUG}`);
    resetTemplateData();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <DrawerForm
          title="Request a Call Back"
          isOpen={isCallBackDrawerOpen}
          onClose={() => {
            dispatch({
              type: SET_ENQUIRY,
              payload: { isTrue: false, redirect_to: '/' },
            });
            setIsCallBackDrawerOpen(false);
          }}
          mobile_number={mobile_number}
          name={name}
          email={email}
          validateInput={validateInput}
          onSubmit={requestCallBackSubmit}
          errors={errors}
          BuisnessType={false}
        />
        {/* dialog for subscription enquiry send */}
        <Dialog
          open={openSubEnq}
          onClose={handleCloseSubModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleCloseSubModal();
                    // history.push('/login');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {userData?.offlineSubscription?.isSubscribed
                ? DIALOG_ALREADY_SUBSCRIPTION_REQ_MSG
                : DIALOG_SUBSCRIPTION_REQ_MSG}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={async () => {
                handleCloseCallModal();
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCallModal}
          onClose={handleCloseCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleCloseCallModal();
                    // history.push('/login');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {userData?.registration?.registration_type !== B2C_ID
                ? DIALOG_NOT_LOGIN_MSG
                : 'Please Login as Seller to use this feature'}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button className="btn-cancel me-2" onClick={handleCloseCallModal}>
              No
            </Button>
            <Button
              onClick={() => {
                handleCloseCallModal();
                if (userData) {
                  if (userData?.registration?.registration_type == B2C_ID) {
                    dispatch({
                      type: SET_ENQUIRY,
                      payload: { isTrue: true, redirect_to: '/subscription' },
                    });
                    history.push('/complete-profile');
                  } else {
                    toggleCallBackDrawer();
                  }
                } else {
                  dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                  });
                  history.push('/login');
                }
              }}
              className="btn-save"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSuccCallModal}
          onClose={handleCloseSuccCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleCloseSuccCallModal();
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {userData?.offlineSubscription?.isSubscribed || ( Object.keys(enquiry)?.length > 2 && enquiry?.status !== 'pending')
                ? DIALOG_ALREADY_SUBSCRIPTION_REQ_MSG : (Object.keys(enquiry)?.length > 2 && enquiry?.status == 'pending' && enquiry?.type) == 'subscription' ? DIALOG_ALREADY_SENT_SUBSCRIPTION_REQ_MSG
                : DIALOG_SUBSCRIPTION_REQ_MSG}
              {/* {DIALOG_SUB_REQ_SUCC_MSG} */}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={() => {
                handleCloseSuccCallModal();
                history.replace('/homepage');
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openErrCallModal}
          onClose={handleCloseErrCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    handleCloseErrCallModal();
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {DIALOG_SUB_REQ_ERR_MSG}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={() => {
                handleCloseErrCallModal();
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openLoginModal}
          onClose={handleCloseLoginModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    dispatch({
                      type: SET_ENQUIRY,
                      payload: {},
                    });
                    handleCloseLoginModal();
                    // history.replace('/login');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMsg}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={() => {
                dispatch({
                  type: SET_ENQUIRY,
                  payload: { isTrue: true, redirect_to: '/subscription' },
                });
                handleCloseLoginModal();
                history.replace('/login');
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            {/* <div >
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <div style={{ flexGrow: 1 }}>
                                        <ElynkerLogoComponent>
                                        <ElynkerLogo width={'100px'} height={'30px'} />
                                        </ElynkerLogoComponent>
                                    </div>
                                    {
                                        <div>

                                            <IconButton
                                                size="medium"
                                                aria-label="user icon"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                <SearchIcon />
                                            </IconButton>


                                        </div>
                                    }
                                </Toolbar>
                            </AppBar>
                        </div> */}
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  // history.goBack()
                  history.replace(location?.state?.from ?? '/homepage');
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <ElynkerLogoComponent>
                  <ElynkerLogo
                    className="ms-3"
                    width={'100px'}
                    height={'30px'}
                  />
                </ElynkerLogoComponent>
              </div>
            </div>
            <Divider
              component="div"
              className="main-divider mt-2 mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '40px' }}>
              <Box sx={{ width: '100%', marginBottom: '40px' }}>
                {/* <img src={SubscriptionBanner} style={{ width: '100%' }}></img> */}

                <Card className="subscription-banner-web-card mb-3">
                  <CardContent className="subscription-banner-web-content">
                    <Box className="subscription-banner-web-main-box">
                      <Box className="subscription-banner-man">
                        {/* <SubscriptionMan /> */}
                        <img src={SubscriptionMan} style={{ width: '197px', height: 'auto', maxWidth: '100%', maxHeight: '100%' }} alt='SubscriptionMan'/>
                      </Box>
                      <Box className="subscription-banner-web-box">
                        <Typography
                          variant="h5"
                          className="subscription-banner-web-text"
                          component="div"
                        >
                          Choose the plan that fits your <br></br> business
                          needs!
                        </Typography>

                        <Box className="sub-box">
                          <div className="sub-inner-box">
                            <QuickOnboardingIcon />
                            <Typography className="subscription-banner-web-subtext">
                              Quick onboarding
                            </Typography>
                          </div>
                          <div className="sub-inner-box">
                            <SolutionsMadeIcon />
                            <Typography className="subscription-banner-web-subtext">
                              Solutions made for you
                            </Typography>
                          </div>
                          <div className="sub-inner-box">
                            <GrowBusinessIcon />
                            <Typography className="subscription-banner-web-subtext">
                              Grow your business digitally
                            </Typography>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              <Grid
                container
                spacing={4}
                justifyContent="space-between"
                className="mb-4"
              >
                {/* eStarter Package */}
                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                  <Box className="package-box" sx={{ flexGrow: 1 }}>
                    <Box className="inner-box">
                      <Box className="inner-sub-box">
                        <Typography className="plan-type">eStarter Leads Package</Typography>
                        {/* <Typography className="free-now-ribbon">
                          Free Now
                        </Typography> */}
                      </Box>

                      <Typography className="plan-text">
                        Come Experience Elynker
                      </Typography>
                      <div style={{ marginBottom: '30px' }}>
                        <Typography className="per-month-price">
                          ₹10,000<span className="per-month"> for 3 Months</span>
                        </Typography>
                        <Typography className="slashed-price">
                          ₹12,000<span className="per-month"> for 3 Months</span>
                        </Typography>
                      </div>
                      <Box className="annual-box">
                        <Typography className="annual-text text-center">
                          Upto 45 Leads in 3 months!
                        </Typography>
                      </Box>
                    </Box>
                    <Divider className="divider" />

                    <Box className="benefits-services">
                      <ul className="package-list">
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Upto 15 leads per month
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Microsite with upto 5k monthly visits
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Marketplace Listing
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Digital Catalogue with upto 10 products
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            E-Business Card
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Google Business Listing +1 Post
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Facebook Business Profile +1 Post
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Instagram Business Profile +1 Post
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Linkedin Business Profile +1 Post
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Business Profile +1 Post
                          </Typography>
                        </li>

                        {/* <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Business Profile Setup
                          </Typography>
                        </li> */}

                        {/* <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Digital Catalog (Upto 20 products or services)
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Micro-site creation
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Google Business Profile Creation
                          </Typography>
                        </li> */}
                      </ul>
                    </Box>
                    {/* <Divider className="divider" /> */}

                    <Box className="terms-box">
                      <Typography
                        className="caption"
                        onClick={handleSubTermsCondition}
                      >
                        Terms and conditions apply*
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Essential Package */}
                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                  <Box
                    className="package-box essential-package-box position-relative"
                    sx={{ flexGrow: 1 }}
                  >
                    <Box className="inner-box">
                      <Box className="inner-sub-box">
                        <Typography className="plan-type">eSsential Leads Package</Typography>
                        {/* <Typography className="limited-time-ribbon">
                          Limited Time Offer
                        </Typography> */}
                      </Box>

                      <Typography className="plan-text">
                        Enhance Your Business
                      </Typography>
                      <div style={{ marginBottom: '30px' }}>
                        <Typography className="per-month-price">
                          ₹18,000
                          {/* <span className="gst-span">(incl. GST)</span>{' '} */}
                          <span className="per-month"> for 3 Months</span>
                        </Typography>
                        {/* <Typography className="slashed-price">
                          ₹9600/month
                        </Typography> */}
                      </div>

                      <Box className="annual-box">
                        <Typography className="annual-text">
                          Upto 75 Leads in 3 months!
                        </Typography>
                      </Box>
                    </Box>
                    <Divider className="divider" />
                    <Box className="benefits-services">
                      <ul className="package-list">
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Upto 25 leads per month
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Microsite with upto 15K monthly visits
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Marketplace Listing
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Digital Catalogue with upto 10 products
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            E-Business Card
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Google Business Listing + 3 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Facebook Business Profile +3 Posts
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Instagram Business Profile +3 Posts
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Linkedin Business Profile +3 Posts
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Business Profile +3 Posts
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Ads for local business areas
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Expert Advisory Service & Unlimited Connects
                          </Typography>
                        </li>
                        {/* <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            400 Whatsapp messages / month
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Local ads to customers near you
                          </Typography>
                        </li> */}
                      </ul>
                    </Box>

                    <Box className="terms-box">
                      <Typography
                        className="caption"
                        onClick={handleSubTermsCondition}
                      >
                        Terms and conditions apply*
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Exclusive Package */}
                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                  <Box className="package-box" sx={{ flexGrow: 1 }}>
                    <Box className="inner-box">
                      <Box className="inner-sub-box">
                        <Typography className="plan-type">eXclusive Leads Package</Typography>
                      </Box>

                      <Typography className="plan-text">
                        Come Experience Exclusive
                      </Typography>
                      <div style={{ marginBottom: '30px' }}>
                        <Typography className="per-month-price">
                          ₹30,000<span className="per-month"> for 3 Months</span>
                        </Typography>
                        {/* <Typography className="slashed-price">
                          6000/month
                        </Typography> */}
                      </div>
                      <Box className="annual-box">
                        <Typography className="annual-text text-center">
                          Upto 150 Leads in 3 months!
                        </Typography>
                      </Box>
                    </Box>
                    <Divider className="divider" />

                    <Box className="benefits-services">
                      <ul className="package-list">
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Upto 50 leads per month
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Microsite with upto 30K monthly visits
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Marketplace Listing
                          </Typography>
                        </li>
                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Digital Catalogue with upto 10 products
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            E-Business Card
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Google Business Listing +8 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Facebook Business Profile + 8 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Instagram Business Profile + 8 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Linkedin Business Profile + 8 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Business Profile + 8 Posts
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Whatsapp Ads for local business areas
                          </Typography>
                        </li>

                        <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Expert Advisory Service & Unlimited Connects
                          </Typography>
                        </li>

                        {/* <li>
                          <CheckCircleGreenIcon />
                          <Typography className="list-text">
                            Dedicated account manager for marketplaces
                          </Typography>
                        </li> */}

                      </ul>
                    </Box>
                    {/* <Divider className="divider" /> */}

                    <Box className="terms-box">
                      <Typography
                        className="caption"
                        onClick={handleSubTermsCondition}
                      >
                        Terms and conditions apply*
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* <Typography
                className="font-skmodernist fs-18 text-gray"
                onClick={handleSubTermsCondition}
              >
                <span className="text-primary-2" style={{ cursor: 'pointer' }}>
                  *Click here
                </span>{' '}
                to view the Terms & Conditions
              </Typography> */}
              <div style={{ marginTop: !isTabletOrMobile ? '100px' : '100px' }}>
                <Box className="text-center mb-4">
                  <Box
                    sx={[
                      stickyButtonStyle,
                      { boxShadow: '0 -4px 5px rgba(0, 0, 0, 0.2)' },
                    ]}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{
                        borderRadius: '6px',
                        textTransform: 'capitalize',
                        width: '200px',
                      }}
                      onClick={async () => {
                        const regId = userData?.registration.id;
                        const fetchData = async () => {
                          if (userData?.user?.id){
                            await getUpdatedUserObjData(userData?.user?.id, ()=>{
                              loading(false);
                            }, ()=>{
                              loading(false);
                            });
                          }
                          loading(true);
                          await getEnquiryById(regId, CALLBACK_TYPE_SUBSCRIPTION, async (enq, r) => {
                            loading(true);
                            console.log('useEffect userData,enquiry', userData, enquiry);
                            console.log('enquiry ',  enq);
                            console.log('enquiry r',  r);
                            if (r == true) {
                              loading(false);
                            } else {
                              //no enq data found on server
                              loading(false);
                            }

                            const userID =
                            userData?.user?.id ||
                            sessionStorage.getItem('random_id');
                          if (userData?.user?.id) {
                            GAEvents(
                              'Profile_Subscription',
                              'Subscription',
                              'clicked',
                              {
                                user_id: userID,
                                reg_uuid: userData?.registration?.uuid,
                                title: 'Subscription',
                              }
                            );
                            // show dialog and send data to event form
                            list: {
                            if (userData) {
                              
                              if (userData?.offlineSubscription?.isSubscribed) {
                                handleOpenSuccCallModal();
                                break list;
                              }
  
                              if (enquiry && Object.keys(enquiry)?.length > 2 && enquiry?.status == 'pending' && enquiry?.type == 'subscription') {
                                  handleOpenSuccCallModal();
                                  break list;
                              }
                              console.log('subscription userData', userData?.offlineSubscription);
                              const data = {
                                mobileNumber: userData?.user?.mobile_number,
                                email: userData?.user?.email,
                                name: userData?.user?.name,
                              };
                              if (userData?.user?.name !== null || userData?.user?.email !== null) {
                                await requestCallBackSubmit(data);
                              } else {
                                //open popup form
                                toggleCallBackDrawer();
                              }
                              }
                            }
                            // handleOpenSubModal();
                            // toggleCallBackDrawer();
                            // window.location.href =
                            //   'https://pages.razorpay.com/pl_PHKnvWti3TWlP9/view';
                          } else {
                            // show dialog and send data to event form

                            setErrorMsg(DIALOG_NOT_LOGIN_MSG);
                            handleOpenLoginModal();
                          }
                          });
                        };
                        loading(true);
                        fetchData();


                      }}
                    >
                      Get started
                    </Button>
                  </Box>
                </Box>
              </div>
              {/* <Grid container spacing={3} justifyContent="center">
                              
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        className='subscription-custom-card'

                                    >
                                        <CardContent className='card-content'>
                                            <Card className="inner-card">
                                                <Typography className='font-skmodernist fs-20 text-white'>
                                                    Basic
                                                </Typography>
                                                <CheckCircleOutline color="primary" />
                                            </Card>
                                            <Typography variant="body2" color="text.secondary">
                                                Our basic free package
                                            </Typography>
                                            <ul>
                                                {[
                                                    "Marketplace Listing",
                                                    "Requirement posting",
                                                    "Unlimited Connects",
                                                    "Unlimited Inquiries",
                                                    "Digital Business Card",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            borderColor: 'primary.main',
                                            boxShadow: 6,
                                            '&:hover': {
                                                boxShadow: 10,
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Typography variant="h6" component="div" color="primary">
                                                    eStarter
                                                </Typography>
                                            </Grid>
                                            <Typography variant="body2" color="text.secondary">
                                                <del>₹2,500</del> Now FREE!!<br />Come Experience elynker
                                            </Typography>
                                            <ul>
                                                {[
                                                    "All benefits from Basic",
                                                    "Digital Catalog",
                                                    "WhatsApp Business Profile",
                                                    "Google Business Profile",
                                                    "Single Pager Website",
                                                    "Social Media profile creation (Instagram, Facebook)",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{ mt: 2, backgroundColor: 'primary.main' }}
                                            >
                                                Get Now
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            borderColor: 'grey.300',
                                            boxShadow: 1,
                                            opacity: 0.5,
                                            position: 'relative',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                p: 1,
                                                borderRadius: 2,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Your essential Package Launching Soon!!
                                        </Typography>
                                        <CardContent>
                                            <Typography variant="h6" component="div" color="primary">
                                                essential
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Coming Soon!!
                                            </Typography>
                                            <ul>
                                                {[
                                                    "All services from eStarter",
                                                    "Dedicated Helpline",
                                                    "Advisory Services",
                                                    "Email Marketing",
                                                    "Social Media Management (Instagram, Facebook, LinkedIn)",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid> */}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

SubscriptionnForm.propTypes = {
  userData: PropTypes.object,
  pushMessage: PropTypes.func,
  postEnquiry: PropTypes.func,
  enquiry: PropTypes.object,
  getEnquiryById: PropTypes.func,
  resetTemplateData: PropTypes.func,
  setTemplateRender: PropTypes.func,
  isLoading: PropTypes.bool,
  isSubscribed: PropTypes.bool,
  getUpdatedUserObjData: PropTypes.func
};

const mapStateToProps = (state) => {
  const { userData, offlineData } = state.signin;
  const { enquiry } = state.b2bVendorlisting;
  const { isLoading } = state.root;

  const isSubscribed = offlineData?.isSubscribed;
  console.log('signin 1233 1211 offlineData', offlineData);
  // console.log('subscription  offlineSubscription', offlineSubscription);
  return {
    userData,
    enquiry,
    isLoading,
    isSubscribed,
  };
};

export default connect(mapStateToProps, {
  postEnquiry,
  getEnquiryById,
  pushMessage,
  resetTemplateData,
  setTemplateRender,
  getUpdatedUserObjData
})(SubscriptionnForm);