// import { LOADING } from '../../../Login/redux/types';
import {
  SAVE_OTP,
  SAVE_SIGNIN_DATA,
  SET_POST_LOGIN_ROUTE_NAME,
  RESET_OTP_DATA,
  UPDATE_SIGNIN_DATA,
  SAVE_USER_SUBS_DATA,
} from '../types';
import {
  B2B_STEP_1_ACTIVE,
  B2B_STEP_2_ACTIVE,
  B2B_STEP_3_ACTIVE,
} from '../../../B2BOnboardingSteps/constants';
import {
  RESET_PROFILE_DETAILS_DATA,
  SET_PROFILE_DETAILS_ADDRESS_1,
  SET_PROFILE_DETAILS_ADDRESS_2,
  SET_PROFILE_DETAILS_CITY,
  SET_PROFILE_DETAILS_STATE,
} from '../../../ProfileDetails/redux/types';
import {
  RESET_B2B_ONBOARD_DATA,
  SET_ACTIVE_STEP,
  SET_STEPPER_DATA,
} from '../../../B2BOnboardingSteps/redux/types';
import {
  getUpdatedUser,
  markOutReachVarifiedApi,
  postSignInUser,
  postVerifyOTP,
} from '../../../../utils/APIs/endpoints';

import {
  FL_STEP_1_ACTIVE,
  FL_STEP_2_ACTIVE,
  FL_STEP_3_ACTIVE,
  FL_STEP_4_ACTIVE,
  FL_STEP_5_ACTIVE,
  // FL_STEP_6_ACTIVE,
} from '../../../FreeLancer/FLOnboardingSteps/constants';

import { RESET_AU_DOC_DATA } from '../../../../components/BussinessDocumentsForm/AU/redux/types';
import {
  ABOUT_COMPANY,
  RESET_B2B_COMPANY_DATA,
  RESET_COMPANY_DATA,
} from '../../../CompanyDetails/redux/types';
import { RESET_CATALOG_DATA } from '../../../AddCatalog/redux/types';
import { RESET_FL_ADD_DATA } from '../../../FreeLancer/FLAddService/redux/types';
import { RESET_IN_DOC_DATA } from '../../../../components/BussinessDocumentsForm/IN/redux/types';
import { RESET_MY_CATALOG_DATA } from '../../../MyCatalog/redux/types';
import { RESET_MY_SERVCIE_DATA } from '../../../MyServices/redux/types';
import { RESET_OFFERED_SERVICE_DATA } from '../../../OfferedServices/redux/types';
import { RESET_CUSTOMER_PROFILE_DATA } from '../../../Profile/redux/types';
import { HOURLY_RATE } from '../../../FreeLancer/FL-SetRate/redux/types';
import {
  SET_FL_ACTIVE_STEP,
  SET_FL_STEPPER_DATA,
} from '../../../FreeLancer/FLOnboardingSteps/redux/types';
import { pushMessage } from '../../../../components/MessageBox/redux/actions/message-box-action';
import { RESET_REQUIREMENT_LIST } from '../../../ActiveRequirements/redux/types';
import { loading } from '../../../../store/actions/rootActions';

// const showLoading = (isLoading) => ({
//   type: LOADING,
//   payload: isLoading,
// });

export const saveOTPNo = (otp_no) => {
  return async (dispatch) => {
    try {
      //   showLoading(true);
      await dispatch({
        type: SAVE_OTP,
        payload: otp_no,
      });
      //   showLoading(false);
    } catch (error) {
      console.log('Error saving mobile_no', error);
    }
  };
};
export const markOutReachVarified = (
  regId,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function () {
    try {
      console.log('markOutReachVarified', regId);

      const response = await markOutReachVarifiedApi(
        {
          status: 'approved',
        },
        regId
      );
      if (response.status === 200) {
        successCallback();
      } else {
        console.log(
          '[b2b-onboarding-steps-action] upOutReachUserData ERROR',
          response.data
        );
        errroCallback();
      }
    } catch (error) {
      console.log(
        '[b2b-onboarding-steps-action] upOutReachUserData ERROR',
        error
      );
      errroCallback();
    }
  };

export const sendSigninData = (
  signInData,
  history,
  successCallback = () => {},
  enquiry,
  fromOutReach
) =>
  async function (dispatch) {
    try {
      //   showLoading(true);
      console.log('====enquiry sendSigninData', enquiry);
      const response = await postSignInUser({
        mobile_number: signInData.mobile_number,
        country_code: signInData.country_code,
        otp: signInData.otp,
      });
      console.log('SUMBIT RESPONSE', response);
      console.log('REGISTRATION TYPE122', response.data?.registration);
      if (response.status === 200) {
        // saving data in interceptor
        // await dispatch({ type: SAVE_SIGNIN_DATA, payload: response.data });

        successCallback(response.data);
        localStorage.setItem('user_loggedIn', true);
        if (fromOutReach) {
          dispatch(markOutReachVarified(response.data?.registration?.id));
        }
        if (response.data.registration.city) {
          await dispatch({
            type: SET_PROFILE_DETAILS_CITY,
            payload: response.data.registration.city,
          });
        }
        if (response.data.registration?.about_company) {
          await dispatch({
            type: ABOUT_COMPANY,
            payload: response.data.registration.about_company,
          });
        }
        if (response.data.registration.state) {
          await dispatch({
            type: SET_PROFILE_DETAILS_STATE,
            payload: response.data.registration.state,
          });
        }
        if (response.data.registration.address1) {
          await dispatch({
            type: SET_PROFILE_DETAILS_ADDRESS_1,
            payload: response.data.registration.address1,
          });
        }
        if (response.data.registration.address2) {
          await dispatch({
            type: SET_PROFILE_DETAILS_ADDRESS_2,
            payload: { label: response.data.registration.address2, value: {} },
          });
        }
        if (response.data.registration.hourly_rate) {
          await dispatch({
            type: HOURLY_RATE,
            payload: response.data.registration.hourly_rate,
          });
        }
        if(response.data.registration.channel == 'email'){
          await dispatch({
            type: SET_POST_LOGIN_ROUTE_NAME,
            payload: 'homepage',
          });
          history.replace('/homepage');
          return;
        }
        if (!response.data.registration.steps_completed) {
          if (response.data.registration.registration_type === 2) {
            // b2b steps
            if (
              !response.data.registration.active_steps ||
              response.data.registration.active_steps === 0
            ) {
              await dispatch({ type: SET_ACTIVE_STEP, payload: 0 });
              await dispatch({
                type: SET_STEPPER_DATA,
                payload: B2B_STEP_1_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 1) {
              await dispatch({ type: SET_ACTIVE_STEP, payload: 1 });
              await dispatch({
                type: SET_STEPPER_DATA,
                payload: B2B_STEP_2_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 2) {
              await dispatch({ type: SET_ACTIVE_STEP, payload: 2 });
              await dispatch({
                type: SET_STEPPER_DATA,
                payload: B2B_STEP_3_ACTIVE,
              });
            }
            await dispatch({
              type: SET_POST_LOGIN_ROUTE_NAME,
              payload: 'b2bonboarding-steps',
            });

            if (enquiry?.isTrue) {
              // history.replace('/b2b-category');
              history.replace(enquiry?.redirect_to);
            } else {
              history.replace('/b2bonboarding-steps');
            }
          } else if (response.data.registration.registration_type === 3) {
            //freelance steps
            if (
              !response.data.registration.active_steps ||
              response.data.registration.active_steps === 0
            ) {
              await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 0 });
              await dispatch({
                type: SET_FL_STEPPER_DATA,
                payload: FL_STEP_1_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 1) {
              await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 1 });
              await dispatch({
                type: SET_FL_STEPPER_DATA,
                payload: FL_STEP_2_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 2) {
              await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 2 });
              await dispatch({
                type: SET_FL_STEPPER_DATA,
                payload: FL_STEP_3_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 3) {
              await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 3 });
              await dispatch({
                type: SET_FL_STEPPER_DATA,
                payload: FL_STEP_4_ACTIVE,
              });
            } else if (response.data.registration.active_steps === 4) {
              await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 4 });
              await dispatch({
                type: SET_FL_STEPPER_DATA,
                payload: FL_STEP_5_ACTIVE,
              });
            }
            // else if (response.data.registration.active_steps === 5) {
            //   await dispatch({ type: SET_FL_ACTIVE_STEP, payload: 5 });
            //   await dispatch({
            //     type: SET_FL_STEPPER_DATA,
            //     payload: FL_STEP_6_ACTIVE,
            //   });
            // }
            await dispatch({
              type: SET_POST_LOGIN_ROUTE_NAME,
              payload: 'fl-onboarding-steps',
            });
            if (enquiry?.isTrue) {
              // history.push('/b2b-category')
              history.replace(enquiry?.redirect_to);
            } else {
              history.replace('/fl-onboarding-steps');
            }
          } else if (response.data.registration.registration_type === 1) {
            await dispatch({
              type: SET_POST_LOGIN_ROUTE_NAME,
              payload: 'homepage',
            });
            if (enquiry?.isTrue) {
              // history.replace('/b2b-category');
              history.replace(enquiry?.redirect_to);
            } else {
              // history.replace('/b2bonboarding-steps');
              history.replace('/homepage');
            }
          }
        } else {
          //   const testTimer = setTimeout(() => {
          //     history.push('/homepage');
          //     clearTimeout(testTimer);
          //   }, 1000);
          // navigateHomePage();
          await dispatch({
            type: SET_POST_LOGIN_ROUTE_NAME,
            payload: 'homepage',
          });
          if (enquiry?.isTrue) {
            // redirect_to: "/b2b-category"
            history.push('/homepage', { shallow: true });
            history.push(enquiry?.redirect_to);
          } else {
            history.replace('/homepage');
          }
        }

        if (!response.data?.registration?.registration_type) {
          // if (fromOutReach) {
          //   history.replace('/homepage');
          // } else {
          await dispatch({
            type: SET_POST_LOGIN_ROUTE_NAME,
            payload: 'complete-profile',
          });
          history.replace('/complete-profile');
          return;
          // }
        }
      }
      if (response.status === 404) {
        // if (fromOutReach) {
        //   history.replace('/homepage');
        // } else {
        await dispatch({
          type: SET_POST_LOGIN_ROUTE_NAME,
          payload: 'complete-profile',
        });
        history.replace('/complete-profile');
        // }
      }
    } catch (error) {
      //status code 404
      if (error?.response?.status === 404) {
        // if (fromOutReach) {
        //   history.replace('/homepage');
        // } else {
        await dispatch({
          type: SET_POST_LOGIN_ROUTE_NAME,
          payload: 'complete-profile',
        });
        history.replace('/complete-profile');
        // }
      } else {
        pushMessage(error?.message + '\n' + error?.response?.data?.message);
      }
    }
  };

export const setUserData = (usrData) =>
  function (dispatch) {
    console.log('action', usrData);
    dispatch({ type: SAVE_SIGNIN_DATA, payload: usrData });
    dispatch({ type: UPDATE_SIGNIN_DATA, payload: {} });
  };

export const resetUserData = () =>
  async function (dispatch) {
    await dispatch({ type: RESET_OTP_DATA });
    await dispatch({ type: RESET_AU_DOC_DATA });
    await dispatch({ type: RESET_B2B_COMPANY_DATA });
    await dispatch({ type: RESET_B2B_ONBOARD_DATA });
    await dispatch({ type: RESET_CATALOG_DATA });
    await dispatch({ type: RESET_COMPANY_DATA });
    await dispatch({ type: RESET_FL_ADD_DATA });
    await dispatch({ type: RESET_IN_DOC_DATA });
    await dispatch({ type: RESET_MY_CATALOG_DATA });
    await dispatch({ type: RESET_OFFERED_SERVICE_DATA });
    await dispatch({ type: RESET_PROFILE_DETAILS_DATA });
    await dispatch({ type: RESET_MY_SERVCIE_DATA });
    await dispatch({ type: RESET_CUSTOMER_PROFILE_DATA });
    await dispatch({ type: RESET_REQUIREMENT_LIST });
  };

export const sendOTPForVerify = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    console.log(data);
    dispatch(loading(true));
    try {
      const response = await postVerifyOTP(data);
      if (response?.status === 200) {
        dispatch(loading(false));
        successCallback(response?.data);
      } else {
        errorCallback(data);
        dispatch(loading(false));
      }
    } catch (error) {
      console.log('ERROR 400 ', error);

      if (error?.response?.status === 400) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback();
      }
      dispatch(loading(false));
    }
  };
};

export const getUpdatedUserObjData = (regId, successCallback = () => {}, errorCallback = () => {}) =>
  async function (dispatch) {
    try {
      const response = await getUpdatedUser(regId);
      if (response.status === 200) {
        console.log(
          'updated user data in home1211,regId',
          response.data,
          regId
        );
        await dispatch({
          type: SAVE_USER_SUBS_DATA,
          payload: response?.data?.offlineSubscription,
        });
        successCallback();
      } else {
        console.log(
          '[b2b-onboarding-steps-action] getUpdatedUserObjData ERROR',
          response.data
        );
        errorCallback();
      }
    } catch (error) {
      console.log(
        '[b2b-onboarding-steps-action] getUpdatedUserObjData ERROR',
        error
      );
      errorCallback();
    }
  };