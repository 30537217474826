import React, { useEffect, useState, useRef } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import PropTypes from 'prop-types';
import { read, utils } from 'xlsx';
import {
  Container,
  Grid,
  Typography,
  Button,
  FormLabel,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Box,
  // IconButton,
  // OutlinedInput,
  // InputAdornment,
  // IconButton,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { connect } from 'react-redux';
// import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import {
  getProductCategories,
  setBusinessType,
  // setSelectedCoverImage,
  getAllProducts,
  setWebAddProductType,
  uploadBulkProduct,
  setBulkProduct,
} from './redux/actions/offered-services-form-action';
import { resetAddCatalogData } from '../AddCatalog/redux/actions/add-catalog-action';
// import CustomizedTreeView from '../../components/TreeDropDown/TreeDropDown';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
import { useMediaQuery } from 'react-responsive';
import { updateUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { loading } from '../../store/actions/rootActions';
import { calculateFilledPercentage } from '../../utils/percent';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import {
  GAEvents,
  handleDownloadDocFormate,
  sendDefaultPageEvent,
} from '../../utils/helper-funtions';
import { prodType, serviceType } from '../../utils/constants';

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      list[map[node.parent_id]]?.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function filterData(data, filterValues) {
  return data.filter((value) => {
    return filterValues
      .trim()
      .split(', ')
      .every((filterValue) => checkValue(value, filterValue));
  });
}

function checkValue(value, filterValue) {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length > 0
  ) {
    if (Array.isArray(value)) {
      return value.some((v) => checkValue(v, filterValue));
    } else {
      return Object.values(value).some((v) => checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

function validateKeys(requiredKeys, obj) {
  console.log('requiredKeys,obj', requiredKeys, obj);
  const objKeys = Object.keys(obj);
  const extraKeys = requiredKeys.filter((key) => !objKeys.includes(key));
  const missingKeys = objKeys.filter((key) => !requiredKeys.includes(key));
  console.log('missingKeys,extraKeys', missingKeys, extraKeys);
  return {
    missingKeys: missingKeys.length > 0 ? missingKeys : null,
    extraKeys: extraKeys.length > 0 ? extraKeys : null,
    isValid: missingKeys.length === 0,
  };
}

const OfferedServicesForm = ({
  onNextHandler,
  onBackHandler,
  userData,
  getProductCategories,
  b2bprodCategories,
  // b2bLvl1Categories,
  // b2bLvl2CategoriesTree,
  // businessType,
  setBusinessType,
  // setSelectedCoverImage,
  selectedLvl1Categories,
  getAllProducts,
  // resetAddCatalogData,
  allProducts,
  webAddProductType,
  setWebAddProductType,
  updateUserData,
  loading,
  isLoading,
  businessType,
  uploadBulkProduct,
  pushMessage,
  setBulkProduct,
  bulkProduct,
}) => {
  // const [categorySearch, setCategorySearch] = useState('');
  // const [filteredCategory, setFilteredCategory] = useState([]);
  // const [open, setOpen] = useState(false);
  // console.log('webAddProductType', webAddProductType);
  // React.useEffect(() => {
  //   console.log('bulkProduct effect', bulkProduct);
  // }, [bulkProduct]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  // eslint-disable-next-line
  const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);
  const theme = createTheme();
  const categoryText = ' What product categories\ndo you provide?';
  let history = useHistory();
  const catalogUploadRef = useRef(null);
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  // const coverInputRef = useRef(null);
  // const catalogUploadRef = useRef(null);

  /* eslint-disable no-unused-vars */
  const handleBTypeChange = (event) => {
    setBusinessType(event.target.value);
  };
  /* eslint-enable no-unused-vars */

  const handleAddProductTypeChange = (event) => {
    console.log('handleAddProductTypeChange', event.target.value);
    setWebAddProductType(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProductCategories('business');
      await getAllProducts(userData?.registration?.id, userData?.accessToken);
    };
    loading(true);
    fetchData();
    loading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    sendDefaultPageEvent(
      userData,
      'Onboarding - Offered Services',
      window.location.href,
      window.location.pathname
    );
  }, []);

  // const handleCoverButtonClick = () => {
  //   coverInputRef.current.click();
  // };

  // const handleCoverFileChange = (e) => {
  //   const selectedFile = e.target.files;
  //   // Handle the selected file as needed, e.g., upload to a server or display preview
  //   console.log('Selected Image:', selectedFile);
  //   setSelectedCoverImage(selectedFile);
  // };

  // const handleCatalogUploadClick = () => {
  //   // catalogUploadRef.current.click();
  //   history.push("/add-catalog");
  // };
  const handleProductUploadClick = () => {
    // catalogUploadRef.current.click();
    // resetAddCatalogData();
    loading(false);
    history.push('/add-catalog');
  };
  // const handleCatalogUploadFileChange = (e) => {
  //   const selectedFile = e.target.files;
  //   // Handle the selected file as needed, e.g., upload to a server or display preview
  //   console.log('Catalog File:', selectedFile);
  // };

  const handleCatalogUploadClick = () => {
    catalogUploadRef.current.click();
  };

  const handleCatalogUploadFileChange = (e) => {
    const selectedFile = e.target.files;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      const requiredKeyValueObj = businessType == 1 ? prodType : serviceType;
      const existingArrayAttribute = jsonData[0];
      // const wantToEdit = existingArrayAttribute?.includes('sku');
      const res = validateKeys(existingArrayAttribute, requiredKeyValueObj);

      if (res?.isValid) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('registrationId', userData.registration.id);
        await uploadBulkProduct(
          formData,
          (_res) => {
            setBulkProduct(file);
            console.log('bulkProduct in store', bulkProduct);

            pushMessage(_res);
          },
          (err) => {
            setBulkProduct(null);
            pushMessage(err);
          }
        );
      } else {
        pushMessage('Invalid file format.');
      }
    };

    reader.readAsArrayBuffer(file);
    e.target.value = null;
    console.log('Catalog File:', selectedFile);
  };

  useEffect(() => {
    // console.log('selectedLvl1Categories', selectedLvl1Categories);
    let filteredLvl1 = [];
    selectedLvl1Categories.forEach((category) => {
      let selectedIdx = categoriesTree.findIndex(
        (itm) => itm.id === category.id
      );
      if (selectedIdx !== -1) {
        filteredLvl1.push(categoriesTree[selectedIdx]);
      }
    });
    setCategoriesTreeFilter(filteredLvl1);
  }, [categoriesTree, selectedLvl1Categories]);

  useEffect(() => {
    if (b2bprodCategories.length > 0) {
      const categoryTree = list_to_tree(b2bprodCategories);
      // console.log('CATEGORY TREE', categoryTree);
      setCategoriesTree(categoryTree);
    }
  }, [b2bprodCategories]);
  /* eslint-disable no-unused-vars */
  const prodImgs = (allProducts) => {
    return (
      <div className="upload-image-container my-1">
        {allProducts?.length > 0 &&
          allProducts.slice(0, 3).map((img, imgIdx) => {
            return (
              <div className="image-inner-field" key={imgIdx}>
                <img
                  src={`${PRODUCT_IMAGE_BASE_URL}${img.default_image}`}
                  alt="product-image"
                  className="product-image"
                />
              </div>
            );
          })}
        <div className="image-inner-field">
          <Button
            type="button"
            fullWidth
            variant="outlined"
            name="add_more_photo"
            value="add_more_photo"
            className="add-button image-inner-field fs-15 font-skmodernist fw-bold"
            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
            onClick={handleProductUploadClick}
          >
            <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
          </Button>
        </div>
      </div>
    );
  };
  /* eslint-enable no-unused-vars */

  const renderAddCatalogProductOption = () => {
    return (
      // <div className="mb-3">
      //   <div className="d-flex justify-content-between">
      //     <InputLabel
      //       htmlFor="inputCIN"
      //       className="text-start fs-14 text-black font-skmodernist"
      //     >
      //       Add Product Catalog
      //     </InputLabel>
      //     {/* <Typography
      //       variant="body1"
      //       align="left"
      //       className="fs-14 text-primary fw-normal font-gotham"
      //       gutterBottom
      //     >
      //       Catalog Template
      //     </Typography> */}
      //   </div>
      //   <Button
      //     type="button"
      //     fullWidth
      //     variant="outlined"
      //     className="add-button mt-2 fs-15 font-skmodernist fw-bold"
      //     sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
      //     onClick={handleAddSingleProductClick}
      //   >
      //     <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
      //   </Button>
      // </div>

      <Grid md={5} lg={5} xs={12} className=" mb-3">
        <div className="d-flex justify-content-between">
          <InputLabel
            htmlFor="inputCIN"
            className="text-start fs-14 text-black font-skmodernist"
          >
            Add Product Catalog
            <br />
            {isDesktopOrLaptop && (
              <span className="text-muted fs-12">(Excel files only)</span>
            )}
          </InputLabel>
          {allProducts?.length > 0 && (
            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-primary fw-normal font-gotham"
              gutterBottom
            >
              <Link
                to={{
                  pathname: '/my-catalog',
                  state: { userType: 'B2B' },
                }}
                // href="/my-catalog"
                // color="inherit"
                style={{ textDecoration: 'none', fontFamily: 'Sk-Modernist' }}
              >
                View Catalog ({allProducts.length})
              </Link>
            </Typography>
          )}
        </div>

        {/* {allProducts.length > 0 ? (
          prodImgs(allProducts)
        ) : ( */}
        <Button
          type="button"
          fullWidth
          variant="outlined"
          className="add-button mt-2 fs-15 font-skmodernist fw-bold"
          sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
          onClick={handleProductUploadClick}
        >
          <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
        </Button>
        {/* )} */}
      </Grid>
    );
  };
  const renderUploadCatalogOption = () => {
    return (
      <>
        <Grid md={5} lg={5} xs={12} className=" mb-3">
          <div className="d-flex justify-content-between">
            <InputLabel
              htmlFor="inputCIN"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Upload your Catalog
            </InputLabel>
            <Typography
              variant="body1"
              style={{ cursor: 'pointer' }}
              onClick={handleDownloadDocFormate}
              align="left"
              className="fs-14 text-primary fw-normal font-gotham"
              gutterBottom
            >
              Catalog Template
            </Typography>
          </div>
          <input
            type="file"
            accept=".xlsx"
            style={{ display: 'none' }}
            ref={catalogUploadRef}
            onChange={handleCatalogUploadFileChange}
          />
          <Button
            type="button"
            fullWidth
            variant="outlined"
            className="add-button mt-2 fs-15 font-skmodernist fw-bold"
            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
            onClick={handleCatalogUploadClick}
          >
            <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
          </Button>
        </Grid>
      </>
    );
  };

  const renderWebProductOptions = () => {
    return (
      <>
        <FormControl fullWidth>
          <FormLabel
            id="upload-catalog-radio"
            className="fs-15 fw-normal font-skmodernist text-black"
          >
            Upload Catalog
          </FormLabel>
          <Divider
            component="div"
            className="divider mt-3 mb-1"
            role="presentation"
          ></Divider>
          <RadioGroup
            row
            aria-labelledby="upload-catalog-radio"
            name="upload-catalog-radio"
            // defaultValue="bulk-upload"
            value={webAddProductType}
            onChange={handleAddProductTypeChange}
          >
            <FormControlLabel
              value="bulk-upload"
              control={<Radio />}
              label="Bulk Upload"
            />
            <FormControlLabel
              value="single-upload"
              control={<Radio />}
              label="Single Upload"
            />
          </RadioGroup>
          <Divider
            component="div"
            className="divider mb-3 mt-1"
            role="presentation"
          ></Divider>
        </FormControl>
        {webAddProductType === 'bulk-upload' && renderUploadCatalogOption()}
        {webAddProductType === 'single-upload' &&
          renderAddCatalogProductOption()}
      </>
    );
  };

  const updateProfileCompletionPercentage = async () => {
    let offeredServicesFilledObj = {
      allProducts: allProducts?.length > 0 ? 'yes' : '',
    };
    let calculatofferedServicesFilledObj = calculateFilledPercentage(
      offeredServicesFilledObj
    );
    localStorage.setItem(
      'profilePercentageStep2',
      calculatofferedServicesFilledObj
    );
    // console.log("===profilePercentageStep2", calculatofferedServicesFilledObj);
  };
  const submitOfferedServiceForm = async () => {
    const userID = userData?.user?.id || sessionStorage.getItem('random_id');
    const btnTitle =
      allProducts?.length > 0 ? 'Submit Button' : 'Skip for Now Button';
    GAEvents('Onboarding - Offered Services', 'Offered Services', 'clicked', {
      user_id: userID,
      reg_uuid: userData?.registration?.uuid,
      title: btnTitle,
    });
    loading(true);
    let newUserData = {
      active_steps: 2,
      steps_completed: true,
    };
    console.log('==userData+newUserData', newUserData);

    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken
    );
    updateProfileCompletionPercentage();
    loading(false);
    onNextHandler();
    history.replace('/congratulations');
  };

  const handleBackButton = async () => {
    loading(true);
    let newUserData = {
      active_steps: 0,
      steps_completed: false,
    };
    await updateUserData(
      userData,
      newUserData,
      userData.registration.id,
      userData.accessToken,
      onBackHandler
    );
    updateProfileCompletionPercentage();
    loading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {categoryText}
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitOfferedServiceForm();
              }}
              style={{ marginTop: '40px' }}
            >
              {/* <FormControl fullWidth>
                <FormLabel
                  id="business-type-radio"
                  className="fs-15 fw-normal font-skmodernist text-black"
                >
                  What is your Business Type
                </FormLabel>
                <Divider
                  component="div"
                  className="divider mt-3 mb-1"
                  role="presentation"
                ></Divider>
                <RadioGroup
                  row
                  aria-labelledby="business-type-radio"
                  name="business-type-radio"
                  defaultValue={'1'}
                  value={businessType}
                  onChange={handleBTypeChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Product"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Service"
                  />
                </RadioGroup>
                <Divider
                  component="div"
                  className="divider mb-3 mt-1"
                  role="presentation"
                ></Divider>
              </FormControl> */}
              {/* <div className=" mb-3">
                <InputLabel
                  htmlFor="inputCompanyName"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Product Category (Max 3)
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <MultiSelect
                    fullWidth
                    productCategory={b2bLvl1Categories}
                    selectedOptions={selectedLvl1Categories}
                    placeholder1={'Select Categories'}
                    placeholder2={'(Max 3)'}
                    maxSelection={3}
                  />
                </FormControl>
              </div> */}

              {isDesktopOrLaptop && renderWebProductOptions()}

              {isTabletOrMobile && renderAddCatalogProductOption()}

              {isDesktopOrLaptop && (
                <>
                  <Box className="d-flex justify-content-between align-items-center my-4">
                    <Button
                      onClick={() => {
                        const userID =
                          userData?.user?.id || sessionStorage.getItem('random_id');
                        GAEvents(
                          'Onboarding - Offered Services',
                          'Offered Services',
                          'clicked',
                          {
                            user_id: userID,
                            reg_uuid: userData?.registration?.uuid,
                            title: 'Back Button',
                          }
                        );
                        handleBackButton();
                      }}
                      variant="text"
                      type="button"
                      className="btn-web-back"
                    >
                      Back
                    </Button>
                    {allProducts?.length > 0 || bulkProduct ? (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-web-next"
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-web-next"
                      >
                        Skip for Now
                      </Button>
                    )}
                  </Box>
                </>
              )}

              {isTabletOrMobile && (
                <>
                  {allProducts?.length > 0 ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      Skip for Now
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      const userID =
                        userData?.user?.id || sessionStorage.getItem('random_id');
                      GAEvents(
                        'Onboarding - Offered Services',
                        'Offered Services',
                        'clicked',
                        {
                          user_id: userID,
                          reg_uuid: userData?.registration?.uuid,
                          title: 'Back Button',
                        }
                      );
                      handleBackButton();
                    }}
                    variant="text"
                    type="button"
                    fullWidth
                    className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                  >
                    Back
                  </Button>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

OfferedServicesForm.propTypes = {
  onNextHandler: PropTypes.func,
  onBackHandler: PropTypes.func,
  getProductCategories: PropTypes.func,
  userData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  b2bprodCategories: PropTypes.array,
  b2bLvl2CategoriesTree: PropTypes.array,
  b2bLvl1Categories: PropTypes.array,
  setBusinessType: PropTypes.func,
  businessType: PropTypes.string,
  selectedCoverImage: PropTypes.object,
  setSelectedCoverImage: PropTypes.func,
  selectedLvl1Categories: PropTypes.array,
  getAllProducts: PropTypes.func,
  resetAddCatalogData: PropTypes.func,
  allProducts: PropTypes.array,
  webAddProductType: PropTypes.string,
  setWebAddProductType: PropTypes.func,
  updateUserData: PropTypes.func,
  loading: PropTypes.func,
  pushMessage: PropTypes.func,
  setBulkProduct: PropTypes.func,

  uploadBulkProduct: PropTypes.uploadBulkProduct,
  isLoading: PropTypes.bool,
  bulkProduct: PropTypes.any,
};

const mapStateToProps = (state) => {
  //singleProduct
  const { userData } = state.signin;
  console.log('===businessType1234', state.offeredServices.bulkProduct);
  const {
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    businessType,
    selectedLvl1Categories,
    allProducts,
    webAddProductType,
    bulkProduct,
  } = state.offeredServices;
  const { isLoading } = state.root;
  return {
    userData,
    b2bprodCategories,
    b2bLvl2CategoriesTree,
    b2bLvl1Categories,
    businessType,
    selectedLvl1Categories,
    allProducts,
    webAddProductType,
    isLoading,
    bulkProduct,
  };
};

export default connect(mapStateToProps, {
  getProductCategories,
  setBusinessType,
  // setSelectedCoverImage,
  getAllProducts,
  resetAddCatalogData,
  setWebAddProductType,
  updateUserData,
  loading,
  uploadBulkProduct,
  pushMessage,
  setBulkProduct,
})(OfferedServicesForm);
