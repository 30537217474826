import React from 'react';
import PropTypes from 'prop-types';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddBtnIcon from '../../assets/add-btn-icon.svg';

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { DIALOG_TITLE } from '../../utils/constants';
import LinearProgress from '@mui/material/LinearProgress';




function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
function DeleteConfirmationPopup({ open, onClose, onConfirm, message }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
      <DialogTitle className="custom-border-header pb-0">
        <Box className="position-relative d-flex justify-content-between">
          <Typography
            className="fs-22 fw-bold font-skmodernist"
            id="alert-dialog-title"
          >
            {DIALOG_TITLE}
          </Typography>
          <Box>
            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions className="modal-footer">
        <Button onClick={onClose} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={onConfirm} className="btn-save">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const renderUploadCatalogOption = ({
  handleDownloadDocFormate,
  catalogUploadRef,
  handleCatalogUploadFileChange,
  handleCatalogUploadClick,
  isEnabled
}) => {
  return (
    <Grid className="mb-3">
      <div className="d-flex justify-content-between">
        <InputLabel
          htmlFor="inputCIN"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Upload your Catalog
        </InputLabel>
        <Typography
          variant="body1"
          style={{ cursor: 'pointer' }}
          onClick={handleDownloadDocFormate}
          align="left"
          className="fs-14 text-primary fw-normal font-gotham"
          gutterBottom
        >
          Catalog Template
        </Typography>
      </div>
      <input
        type="file"
        accept=".xlsx"
        style={{ display: 'none' }}
        ref={catalogUploadRef}
        onChange={handleCatalogUploadFileChange}
        disabled={isEnabled}
      />
      <Button
        type="button"
        fullWidth
        variant="outlined"
        className="add-button mt-2 fs-15 font-skmodernist fw-bold"
        sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
        onClick={handleCatalogUploadClick}
        disabled={isEnabled}
      >
        <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
      </Button>
    </Grid>
  );
};

function AddProductOptions({
  open,
  onClose,
  onConfirm,
  handleDownloadDocFormate,
  handleCatalogUploadFileChange,
  updatePercent,
  isEnabled
}) {
  const catalogUploadRef = React.useRef(null);
  const [webAddProductType, setWebAddProductType] =
    React.useState('single-upload');

  const handleAddProductTypeChange = (event) => {
    console.log('handleAddProductTypeChange', event.target.value);
    setWebAddProductType(event.target.value);
  };
  const handleCatalogUploadClick = () => {
    catalogUploadRef.current.click();
    // onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="custom-border-header pb-0">
        <FormControl fullWidth>
          <FormLabel
            id="upload-catalog-radio"
            className="fs-15 fw-normal font-skmodernist text-black"
          >
            Upload Catalog
          </FormLabel>
          <Divider className="divider mt-3 mb-1" />
          <RadioGroup
            row
            aria-labelledby="upload-catalog-radio"
            defaultValue="single-upload"
            name="upload-catalog-radio"
            value={webAddProductType}
            onChange={handleAddProductTypeChange}
          >
            <FormControlLabel
              value="bulk-upload"
              control={<Radio />}
              label="Bulk Upload"
            />
            <FormControlLabel
              value="single-upload"
              control={<Radio />}
              label="Single Upload"
            />
          </RadioGroup>
          <Divider className="divider mb-3 mt-1" />
        </FormControl>
      </DialogTitle>
      <DialogContent>
        {webAddProductType === 'bulk-upload' &&
        <>
          {renderUploadCatalogOption({
            handleDownloadDocFormate,
            catalogUploadRef,
            handleCatalogUploadFileChange,
            handleCatalogUploadClick,
            isEnabled
          })}
          <Box sx={{ width: '100%' }}>
            <Typography>
              Progress
            </Typography>
            <LinearProgressWithLabel value={updatePercent} />
          </Box>
          </>
          }
      </DialogContent>
      <DialogActions className="modal-footer">
        <Button onClick={onClose} className="btn-cancel me-2">
          Cancel
        </Button>
        {webAddProductType !== 'bulk-upload' && (
          <Button
            onClick={() => {
              onConfirm(webAddProductType);
              onClose();
            }}
            className="btn-save"
          >
            Yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const ConfirmationDialog = ({
  open,
  handleClose,
  title = 'Elynker',
  content = 'Are you sure you want to proceed?',
  onConfirm,
  confirmText = 'Yes',
  cancelText = 'No',
  addMsg = ''
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="custom-border-header pb-0">
        <Box className="position-relative d-flex justify-content-between">
          <Typography
            className="fs-22 fw-bold font-skmodernist"
            id="confirmation-dialog-title"
          >
            {title}
          </Typography>
          <Box>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {content} <br></br>
          <span className="text-muted">{addMsg}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="modal-footer">
        {cancelText && (
          <Button className="btn-cancel me-2" onClick={handleClose}>
            {cancelText}
          </Button>
        )}
        <Button
          onClick={() => {
            onConfirm();
            handleClose();
          }}
          className="btn-save"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
};
ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  addMsg: PropTypes.string
};
AddProductOptions.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  handleDownloadDocFormate: PropTypes.func,
  handleCatalogUploadFileChange: PropTypes.func,
  handleCatalogUploadClick: PropTypes.func,
  updatePercent: PropTypes.number,
  isEnabled: PropTypes.bool
};
LinearProgressWithLabel.propTypes = {
  value: PropTypes.any
}


export { DeleteConfirmationPopup, AddProductOptions, ConfirmationDialog };
