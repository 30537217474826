import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Paper } from '@mui/material';
const ResponsiveImageCarousel = ({ images = [
    'https://via.placeholder.com/400x300?text=Image+1',
    'https://via.placeholder.com/400x300?text=Image+2',
    'https://via.placeholder.com/400x300?text=Image+3',
    'https://via.placeholder.com/400x300?text=Image+4',
    'https://via.placeholder.com/400x300?text=Image+5',
] }) => {
    return (
        <Carousel
            className='modal-carousel'
            autoPlay={true}
            showThumbs={true}
            showArrows={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            infiniteLoop={true}
            emulateTouch={true}
            renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        style={{
                            position: "absolute",
                            left: '5px',
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "rgb(238 236 236 / 69%)",
                            border: "none",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            zIndex: 10,
                            fontSize: '25px',
                            color: '#864ffd',
                        }}
                    >
                        ⮜
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        style={{
                            position: "absolute",
                            right: '5px',
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "rgb(238 236 236 / 69%)",
                            border: "none",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            zIndex: 10,
                            fontSize: '25px',
                            color: '#864ffd',
                        }}
                    >
                        ⮞
                    </button>
                )
            }
        >
            {images && images.map((image, index) => (
                <div key={index}>
                    <Paper elevation={3}>
                        <img src={image} alt={`Slide ${index}`} />
                    </Paper>
                </div>
            ))}

        </Carousel>
    );
};



ResponsiveImageCarousel.propTypes = {
    images: PropTypes.array,
};
export default ResponsiveImageCarousel;
